import React from 'react';

import ContextWrapper from 'components/ContextWrapper';
import Launcher from 'components/Launcher';
import LauncherMenu from 'components/LauncherMenu';
import Portal from 'components/PortalContainer';

const App: React.FC = () => {
  return (
    <div className="herald-customer-portal">
      <ContextWrapper>
        <Portal />
        <Launcher />
        <LauncherMenu />
      </ContextWrapper>
    </div>
  );
};

export default App;
