import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { colors, styles, icons } from 'herald-fe-shared';
import { Context } from 'components/ContextWrapper';
import { ConfigContext } from 'components/ConfigWrapper';

import HeraldFrame from 'components/HeraldFrame';

export const LAUNCHER_WIDTH = '60px';

const getContainerStyle = (config?: IHeraldPortalStyleConfig) => ({
  width: LAUNCHER_WIDTH,
  height: LAUNCHER_WIDTH,
  borderRadius: '50%',
  overflow: 'hidden',
  position: 'fixed' as any,
  bottom: 24,
  right: config?.position === 'left' ? 'initial' : '24px',
  left: config?.position === 'left' ? '24px' : 'initial',
  boxShadow: styles.BOX_SHADOW,
  transition: '250ms all',
  animation: 'fadeup 250ms',
  zIndex: 10000001,
});

const Styled = styled.div<{ config?: IHeraldPortalStyleConfig }>`
  &:hover {
    box-shadow: 0 0 20px ${colors.BLACK(0.3)};
  }

  @keyframes fadeup {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const HeraldLauncher = styled.div<{ config?: IHeraldPortalStyleConfig }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) => props.config?.primaryColor || colors.PURPLE_LIGHT()};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 40%;
    height: 40%;
    fill: ${colors.WHITE()};
  }
`;

const LauncherContainer: React.FC = () => {
  const { config } = useContext(ConfigContext);
  const { setMenuOpen } = useContext(Context);
  const containerStyle = useMemo(() => getContainerStyle(config.style), [
    config,
  ]);
  return (
    <Styled config={config.style} style={containerStyle}>
      <HeraldFrame>
        <HeraldLauncher
          config={config.style}
          onClick={() => {
            if (setMenuOpen) {
              setMenuOpen(true);
            }
          }}
        >
          <icons.Question style={{ fill: config.style?.secondaryColor }} />
        </HeraldLauncher>
      </HeraldFrame>
    </Styled>
  );
};

export default LauncherContainer;
