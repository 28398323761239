import { useCallback, useRef, useEffect } from 'react';

export const useClickOutside = (handler: () => void) => {
  const ref = useRef(null);
  const handleClickOutside = useCallback(
    (e) => {
      if (ref && ref.current && !(ref as any).current.contains(e.target)) {
        handler();
      }
    },
    [ref, handler]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  return ref;
};
