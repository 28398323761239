import React, { useContext, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { colors, styles, components, icons } from 'herald-fe-shared';
import { Context } from 'components/ContextWrapper';
import { ConfigContext } from 'components/ConfigWrapper';

import HeraldFrame from 'components/HeraldFrame';
import { LAUNCHER_WIDTH } from 'components/Launcher';
import { useClickOutside } from 'lib/hooks';

export const MENU_WIDTH = '160px';
export const MENU_HEIGHT_SINGLE = 40;

declare const window: any;

const getContainerStyle = (config?: IHeraldPortalStyleConfig) => ({
  position: 'fixed' as any,
  width: MENU_WIDTH,
  borderRadius: 5,
  background: colors.GRAY_1(),
  boxShadow: styles.BOX_SHADOW,
  bottom: 24,
  right:
    config?.position === 'left' ? 'initial' : `calc(${LAUNCHER_WIDTH} + 32px)`,
  left:
    config?.position === 'left' ? `calc(${LAUNCHER_WIDTH} + 32px)` : 'initial',
  overflow: 'hidden',
  zIndex: 10000001,
});

const HeraldLauncherMenu = styled.div<{ primaryColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .herald-launcher-menu {
    cursor: pointer;
  }

  .herald-launcher-menu__button {
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: 250ms all;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    color: ${(props) => props.primaryColor};
  }
  .herald-launcher-menu__button:hover {
    background: ${(props) =>
      props.primaryColor
        ? colors.hexToRgb(props.primaryColor.slice(1))(0.1)
        : colors.PURPLE_LIGHT(0.1)};
  }
  .herald-launcher-menu__button__icon {
    fill: ${(props) => props.primaryColor || colors.PURPLE_LIGHT()};
    margin-right: 8px;
  }
`;

const LauncherMenuOption: React.FC<any> = (props) => {
  const { setMenuOpen } = useContext(Context);
  const { icon: Icon, label, href, onClick } = props;

  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick();
    }
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  }, [onClick, setMenuOpen]);

  const Button = useMemo(
    () => (
      <button
        className="herald-launcher-menu__button"
        onClick={onClickCallback}
      >
        <Icon className="herald-launcher-menu__button__icon" />
        {label}
      </button>
    ),
    [label, onClickCallback]
  );
  return href && !onClick ? (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {Button}
    </a>
  ) : (
    Button
  );
};

const LauncherContainer: React.FC = () => {
  const { config } = useContext(ConfigContext);
  const { setOpen, menuOpen, setMenuOpen } = useContext(Context);
  const { seeRequests, productUpdates, chat, roadmap, style } = config;

  const onChat = useCallback(() => {
    if (!chat?.provider) {
      return;
    }
    if (setOpen) {
      setOpen(false);
    }
    switch (chat.provider) {
      case 'Intercom':
        if (window.Intercom && setOpen) {
          window.Intercom('show');
        }
        return;
    }
  }, [chat, setOpen]);

  const ref = useClickOutside(() => {
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  });

  const containerStyle = useMemo(() => {
    const base = getContainerStyle(style);
    const len =
      0 +
      (seeRequests?.show === false ? 0 : 1) +
      (productUpdates?.href ? 1 : 0) +
      (chat?.provider ? 1 : 0) +
      (roadmap?.href ? 1 : 0);
    return { ...base, height: len * MENU_HEIGHT_SINGLE };
  }, [seeRequests, productUpdates, chat, roadmap, style]);

  return (
    <components.ScaleIn visible={menuOpen}>
      <div ref={ref} style={containerStyle}>
        <HeraldFrame>
          <HeraldLauncherMenu {...config.style}>
            <div className="herald-launcher-menu">
              {seeRequests?.show !== false && (
                <LauncherMenuOption
                  icon={icons.Bullhorn}
                  label="My Requests"
                  onClick={() => {
                    if (setOpen && setMenuOpen) {
                      setOpen(true);
                      setMenuOpen(false);
                    }
                  }}
                />
              )}
              {chat?.provider && (
                <LauncherMenuOption
                  icon={icons.CommentAltDots}
                  label="Chat With Us"
                  onClick={onChat}
                />
              )}
              {productUpdates?.href && (
                <LauncherMenuOption
                  icon={icons.Rocket}
                  label="Product Updates"
                  href={productUpdates.href}
                />
              )}
              {roadmap?.href && (
                <LauncherMenuOption
                  icon={icons.DraftingCompass}
                  label="Product Roadmap"
                  href={roadmap.href}
                />
              )}
            </div>
          </HeraldLauncherMenu>
        </HeraldFrame>
      </div>
    </components.ScaleIn>
  );
};

export default LauncherContainer;
