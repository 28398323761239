import React, { useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { components, colors, styles } from 'herald-fe-shared';

import HeraldFrame from 'components/HeraldFrame';

import { Context } from 'components/ContextWrapper';
import { LAUNCHER_WIDTH } from 'components/Launcher';
import { useClickOutside } from 'lib/hooks';
import { ConfigContext } from 'components/ConfigWrapper';

const getContainerStyle = (config?: IHeraldPortalStyleConfig) => ({
  position: 'fixed' as any,
  width: `calc(100% - 24px)`,
  height:
    config?.position === 'left'
      ? `calc(100% - 80px)`
      : `calc(100% - ${LAUNCHER_WIDTH} - 80px)`,
  borderRadius: 5,
  background: colors.GRAY_1(),
  boxShadow: styles.BOX_SHADOW,
  maxWidth: 320,
  right: 24,
  bottom: config?.position === 'left' ? 48 : `calc(${LAUNCHER_WIDTH} + 48px)`,
  overflow: 'hidden',
  zIndex: 10000001,
});

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  .herald__portal__container {
    height: 100%;
    width: 100%;
  }
`;

const RequestsPortalContainer: React.FC = () => {
  const {
    setOpen,
    tab,
    setTab,
    mode,
    setMode,
    company,
    contact,
    requests,
    error,
    open,
  } = useContext(Context);
  const { config } = useContext(ConfigContext);
  const ref = useClickOutside(() => {
    if (setOpen) {
      setOpen(false);
    }
  });

  const onRequestClose = useCallback(() => {
    if (setOpen) {
      setOpen(false);
    }
  }, [setOpen]);

  const style = useMemo(() => getContainerStyle(config.style), [config]);

  return (
    <components.FadeLeft visible={open} className="herald__portal__container">
      <div ref={ref} style={style}>
        <HeraldFrame>
          <StyledContainer>
            <components.Portal
              company={company}
              contact={contact}
              requests={requests}
              tab={tab}
              setTab={setTab}
              mode={mode}
              setMode={setMode}
              onRequestClose={onRequestClose}
              primaryColor={config.style?.primaryColor}
              error={error}
            />
          </StyledContainer>
        </HeraldFrame>
      </div>
    </components.FadeLeft>
  );
};

export default RequestsPortalContainer;
