import React, { useContext } from 'react';
import { createGlobalStyle, StyleSheetManager } from 'styled-components';
import Frame, { FrameContext } from 'react-frame-component';
import { components } from 'herald-fe-shared';

const FrameStyle = createGlobalStyle`
  body, html, .frame-root, .frame-content {
    width: 100%;
    height: 100%;
  }
`;

const HeraldInsideFrame: React.FC = (props) => {
  const { document } = useContext(FrameContext);
  return (
    <StyleSheetManager target={document.head}>
      <div
        className="herald-customer-portal"
        style={{ width: '100%', height: '100%' }}
      >
        <style>@import url('https://use.typekit.net/wvk0xro.css');</style>
        {props.children}
        <components.GlobalStyle />
        <FrameStyle />
      </div>
    </StyleSheetManager>
  );
};

const HeraldFrame: React.FC = (props) => {
  return (
    <Frame style={{ width: '100%', height: '100%', border: 'none' }}>
      <HeraldInsideFrame>{props.children}</HeraldInsideFrame>
    </Frame>
  );
};

export default HeraldFrame;
