import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';

import * as serviceWorker from 'serviceWorker';
import ConfigWrapper from 'components/ConfigWrapper';

declare const window: any;

const init = (config: IHeraldPortalConfiguration) => {
  if (!window.HeraldPortal.root) {
    window.HeraldPortal.root = document.createElement('div');
    document.body.appendChild(window.HeraldPortal.root);
  }
  ReactDOM.render(
    <ConfigWrapper {...config}>
      <App />
    </ConfigWrapper>,
    window.HeraldPortal.root
  );
};

const shutdown = () => {
  if (window.HeraldPortal.root) {
    window.HeraldPortal.root.remove();
    window.HeraldPortal.root = null;
  }
};

window.HeraldPortal = {
  init,
  shutdown,
  root: null,
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
