import React, { useState, useMemo, useContext } from 'react';
import useSWR from 'swr';
import {
  Snippet,
  Company,
  InternalContact,
  IPortal,
  PortalTab,
  PortalMode,
} from 'herald-fe-shared';

import { ConfigContext } from 'components/ConfigWrapper';

const TOKEN_PREFIX = `ct_`;

export interface IContext extends IPortal {
  open: boolean;
  setOpen?: (o: boolean) => void;
  menuOpen: boolean;
  setMenuOpen?: (o: boolean) => void;
  error?: string;
}

const defaultContext = {
  open: false,
  menuOpen: false,
  tab: PortalTab.Pending,
  mode: PortalMode.Contact,
};

export const Context = React.createContext<IContext>(defaultContext);

type QuotesStatus = 'all' | 'archived' | 'active';

const useMyFeedback = (
  token: string,
  skip?: boolean,
  status?: QuotesStatus,
  company?: boolean
) => {
  const fetcher = (url: string, token?: string) => {
    if (token) {
      return fetch(url, {
        headers: {
          Authorization: `Bearer ${
            token.startsWith(TOKEN_PREFIX) ? token : TOKEN_PREFIX + token
          }`,
        },
      }).then((r) => r.json());
    }
    return Promise.reject();
  };
  return useSWR<{
    quotes: Snippet[];
    company: Company;
    contact: InternalContact;
  }>(
    token && !skip
      ? [
          `${process.env.REACT_APP_API_BASE_URL || ''}/beta/quotes?status=${
            status || 'all'
          }&source=${company ? 'company' : 'contact'}`,
          token,
        ]
      : null,
    fetcher,
    {
      errorRetryInterval: 1000,
      focusThrottleInterval: 1000000,
    }
  );
};

const ContextWrapper: React.FC = (props) => {
  const [open, setOpen] = useState(defaultContext.open);
  const [menuOpen, setMenuOpen] = useState(defaultContext.menuOpen);
  const [tab, setTab] = useState<PortalTab>(defaultContext.tab);
  const [mode, setMode] = useState<PortalMode>(defaultContext.mode);

  const { token } = useContext(ConfigContext);

  const { data, error } = useMyFeedback(
    token,
    !open,
    tab === PortalTab.Pending ? 'active' : 'archived',
    mode === PortalMode.Company
  );

  const company = useMemo(() => data?.company, [data]);

  const requests = data?.quotes.map((q) => ({
    summary: {
      id: q.id,
      implementationState: (q as any).implementationState,
    },
    snippets: [q],
  }));

  const contact = useMemo(() => data?.contact, [data]);

  return (
    <Context.Provider
      value={{
        open,
        setOpen,
        menuOpen,
        setMenuOpen,
        tab,
        setTab,
        mode,
        setMode,
        company,
        contact,
        requests: requests as any,
        error,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ContextWrapper;
