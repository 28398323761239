import React from 'react';

export const ConfigContext = React.createContext<IHeraldPortalConfiguration>({
  slug: '',
  token: '',
  config: {},
});

const ConfigContextWrapper: React.FC<IHeraldPortalConfiguration> = (props) => {
  return (
    <ConfigContext.Provider value={props}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigContextWrapper;
